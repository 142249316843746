/* eslint-disable no-unused-vars */
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// eslint-disable-next-line object-curly-newline
import { ref, /* computed, watch, */ onMounted } from '@vue/composition-api'
import store from '@/store'
import moment from 'moment'
import Vue from 'vue'

export default function userProducto(refSidebarFormProducto, props, emit, getProducto) {
  // Use toast
  const loading = ref(false)
  const toast = useToast()

  onMounted(() => {
  })

  // ------------------------------------------------
  // event
  // ------------------------------------------------

  const blankEvent = {
    descripcion: '',
    nombre: '',
    precioUnitario: 0,
    myFiles: [],
    imagenes: [],
  }
  const event = ref(blankEvent)
  const clearEventData = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  // eslint-disable-next-line no-unused-vars
  const updateEventInCalendar = (
    updatedEventData,
    propsToUpdate,
    extendedPropsToUpdate,
  ) => {
    toast({
      component: ToastificationContent,
      props: {
        title: 'Evento Actualizado',
        icon: 'CheckIcon',
        variant: 'success',
      },
    })
  }
  // ------------------------------------------------
  // (UI) removeEventInCalendar
  // ------------------------------------------------
  const removeEventInCalendar = () => {
    toast({
      component: ToastificationContent,
      position: 'bottom-right',
      props: {
        title: 'Producto Eliminado',
        icon: 'TrashIcon',
        variant: 'danger',
      },
    })
    getProducto()
  }

  // ------------------------------------------------
  // addEvent
  // ------------------------------------------------
  const addEvent = async eventData => {
    const formDataProducto = new FormData()
    const directorio = JSON.parse(localStorage.getItem('empresa'))
    formDataProducto.append('descripcion', eventData.descripcion)
    formDataProducto.append('idEmpresa', directorio.idDirectorio)
    formDataProducto.append('nombre', eventData.nombre)
    formDataProducto.append('precioUnitario', eventData.precio)
    console.log(eventData.myFiles)
    eventData.myFiles.forEach(async file => {
      formDataProducto.append('imagenes', file.file, file.file.name)
    })
    await store
      .dispatch('panel/PRODUCTO_CREATE', formDataProducto)
      .then(async response => {
        if (response.success) {
          getProducto()
          toast({
            component: ToastificationContent,
            props: {
              title: 'Evento Registrado',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      })
      .catch(err => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'No se actualizó el evento',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {})
  }

  // ------------------------------------------------
  // updateEvent
  // ------------------------------------------------
  const updateEvent = async eventData => {
    const formDataProducto = new FormData()
    /* eventData.myFiles.forEach((file, index) => { */

    //  formDataProducto.append('imagenes', file, index)
    /*  if (!(file instanceof File)) { */

    /*  store.dispatch('multimedia/MULTIMEDIA_BY_URL', { isthumb: true, url: 'PRODUCTO-EKWVASXCPV.jpg' }).then(response => {
      const images = []
      const fileToUpload = new File([response], 'qwr.jpg', {
        type: 'image/jpg', // this should be from your string split of the filename to check the file upload type.
        lastModified: new Date(),

      })
      images.push(fileToUpload)
      listFotos.value.push(fileToUpload)
      event.value.myFiles = listFotos.value
      refSidebarFormProducto.value.myFiles = listFotos.value
      isEventHandlerSidebarActive.value = true
    }) */
    const archivos = eventData.myFiles.filter(file => (file.file instanceof File))
    archivos.forEach((file, index) => {
      formDataProducto.append('imagenes', file.file, file.file.name)
    })
    const directorio = JSON.parse(localStorage.getItem('empresa'))
    formDataProducto.append('descripcion', eventData.descripcion)
    formDataProducto.append('idEmpresa', directorio.idDirectorio)
    formDataProducto.append('nombre', eventData.nombre)
    formDataProducto.append('precioUnitario', eventData.precio)
    formDataProducto.append('idProducto', eventData.idProducto)
    store
      .dispatch('panel/PRODUCTO_UPDATE', formDataProducto)
      .then(async twsrt => {
        if (twsrt.success) {
          getProducto()
          toast({
            component: ToastificationContent,
            props: {
              title: 'Evento Registrado',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }
      })
      .catch(err => {
        console.log(err)
        toast({
          component: ToastificationContent,
          props: {
            title: 'No se actualizó el evento',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  /*   }
    }) */
  }
  // ------------------------------------------------
  // removeEvent
  // ------------------------------------------------
  const removeEvent = producto => {
    Vue.swal({
      title: '¿Está seguro?',
      text: '¿Deseas eliminar el producto?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      cancelButtonText: 'No',
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-primary ml-1',
      },
      buttonsStyling: false,
    }).then(async result => {
      if (result.value) {
        const productoId = producto.idProducto
        store
          .dispatch('panel/PRODUCTO_DELETE', { id: productoId })
          .then(() => {
            removeEventInCalendar(productoId)
          }).catch(() => {
            toast({
              component: ToastificationContent,
              position: 'bottom-right',
              props: {
                title: 'Oops! Ha sucedido algo.',
                icon: 'TrashIcon',
                variant: 'danger',
              },
            })
          })
      }
    })
  }

  /* watch(isEventHandlerSidebarActive, () => {
    if (!isEventHandlerSidebarActive.value) {
    }} */

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)
  return {
    isCalendarOverlaySidebarActive,
    event,
    clearEventData,
    addEvent,
    updateEvent,
    removeEvent,
    isEventHandlerSidebarActive,
    loading,
  }
}
