<template>
  <BCard id="seccion-content">
    <ProductoEventHandler
      :is-toggle-sidebar.sync="isToggleSidebar"
      :form-type="formType"
      :data-edit="dataEdit"
      @refetch-data="refetchData"
      @error-data="showError"
    />
    <BCard
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <BRow>
          <!-- Per Page -->
          <BCol
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Mostrando</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>registros</label>
          </BCol>

          <!-- Search -->
          <BCol
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="query"
                class="d-inline-block mr-1"
                placeholder="Buscar producto..."
              />
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="selectADR.text"
                right
                variant="outline-primary"
                class="ml-1 mr-1"
              >
                <b-dropdown-item
                  v-for="sortOption in searchADR"
                  :key="sortOption.value"
                  @click="selectADR = sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>
              <b-button
                variant="primary"
                @click="openForm('new')"
              >
                <span class="text-nowrap">Agregar Nuevo</span>
              </b-button>
            </div>
          </BCol>
        </BRow>
      </div>

      <BTable
        ref="refPromocionListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="fields"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No se encontraron registros"
        :busy="isBusy"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner
              variant="primary"
              class="align-middle"
            />
            <strong class="ml-1">Cargando...</strong>
          </div>
        </template>
        <template #cell(titulo)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :variant="`light-primary`"
              >
                <font-awesome-icon
                  :icon="data.item.icono || 'fa-solid fa-list'"
                />
              </b-avatar>
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.nombre }}
            </span>
            <small
              v-if="data.item.precioUnitario"
              class="text-muted d-block "
            >S/. {{ formatNumberWithCommas(data.item.precioUnitario) }}</small>
          </b-media>
        </template>
        <template #cell(directorio)="data">
          <b-badge
            variant="light-secondary"
            class="mr-50 d-block"
          >
            {{ data.item.directorio.nombreComercial }}
          </b-badge>
          <b-badge
            variant="light-info"
            class="mr-50 d-block mt-50"
          >
            {{ data.item.directorio.tipoServicioTuristico.labelFrontend }}
          </b-badge>
        </template>
        <template #cell(estado)="data">
          <b-badge
            v-if="data.item.estadoProducto === 'A'"
            v-b-tooltip.hover.v-success
            :title="`El ${ moment(String(data.item.publishApprove)).format('DD/MM/YYYY') }`"
            pill
            variant="light-success"
            class="text-capitalize"
          >
            APROBADO
          </b-badge>
          <b-badge
            v-if="data.item.estadoProducto === 'R'"
            pill
            variant="light-info"
            class="text-capitalize"
          >
            PENDIENTE
          </b-badge>

          <b-badge
            v-if="data.item.estadoProducto === 'D'"
            v-b-tooltip.hover.v-danger
            :title="`El ${ moment(String(data.item.publishDisapproval)).format('DD/MM/YYYY') }
            Motivo: ${data.item.disapprovalReasons}`"
            pill
            variant="light-danger"
            class="text-capitalize"
          >
            DESAPROBADO
          </b-badge>

          <b-badge
            v-b-tooltip.hover.v-secondary
            :title="`El ${ moment(String(data.item.publishRequest)).format('DD/MM/YYYY') }`"
            variant="light-secondary"
            class="mr-50 d-block"
          >
            REGISTRADO
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(acciones)="data">
          <span>
            <!--   <feather-icon
              v-b-tooltip.hover.v-info
              icon="ShoppingBagIcon"
              class="cursor-pointer mr-50"
              title="Producto"
              @click="showFotosModal(data.item)"
            /> -->
            <div class="d-inline wl200">
              <feather-icon
                v-if="data.item.publishApprove == null && data.item.publishDisapproval == null"
                v-b-tooltip.hover.v-success
                icon="EditIcon"
                class="cursor-pointer mr-50"
                title="Editar"
                @click="editProducto(data.item)"
              />
              <feather-icon
                v-b-tooltip.hover.v-danger
                icon="TrashIcon"
                title="Eliminar"
                class="cursor-pointer mr-50"
                @click="removeEvent(data.item)"
              />
            </div>
          </span>
        </template>
        <template #cell()="data">
          <span class="d-block text-nowrap text-justify">
            {{ data.value }}
          </span>
        </template>
      </BTable>
      <div class="mx-2 mb-2">
        <BRow>
          <BCol
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Mostrando {{ from }} a {{ to }} de {{ of }} registros</span>
          </BCol>
          <!-- Pagination -->
          <BCol
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="page"
              :total-rows="totalItems"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </BCol>
        </BRow>
      </div>
    </BCard>
  </BCard>
</template>

<script>
import {
  BAvatar,
  BTable,
  BCol,
  BCard,
  /*   BCardHeader, */
  BMedia,
  BRow,
  BFormInput,
  BBadge,
  BPagination,
  BSpinner,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BButton,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'
import vSelect from 'vue-select'
import store from '@/store'
import { useNotify } from '@/helpers/toast'
import config from '@/services/config'
import moment from 'moment'
import formatNumberWithCommas from '@/utils/number'
import { showError } from '@/helpers'
import ProductoEventHandler from './producto-event-handler/ProductoEventHandler.vue'
import useProducto from './useProducto'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  components: {
    BCard,
    /* BImg, */

    BMedia,
    BAvatar,
    BPagination,
    BBadge,
    BCol,
    BButton,
    BRow,
    BTable,
    vSelect,
    BFormInput,
    BSpinner,
    ProductoEventHandler,
    BDropdown,
    BDropdownItem,
  },
  props: {
    idAtractivo: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'titulo',
          label: 'Producto',
        },
        {
          key: 'directorio',
          label: 'Empresa',
          thClass: ['text-center'],
          tdClass: ['text-center'],
        },
        {
          key: 'estado',
          label: 'Estado',
          tdClass: ['text-center'],
          thClass: ['text-center'],
        },
        {
          key: 'Acciones',
          label: 'Acciones',
          tdClass: ['text-center'],
          thClass: ['text-center'],
        },
      ],
      sortBy: null,

    }
  },
  mounted() {
    this.getProducto()
  },
  methods: {
    showError,
    clean() {
      this.items = []
    },
    showFotosModal(item) {
      const img = item.imagenes.split('***')
      this.listFotos = []
      img.map(foto => this.listFotos.push(
        `${config.baseURL}${config.contextPI}/api/v1/multimedia/byUrl?isthumb=true&url=${foto}`,
      ))
      this.modalshow = true
      return this.listFotos
    },
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const { emit } = context
    const isToggleSidebar = ref(false)
    const dataEdit = ref({})
    const formType = ref('')
    const page = ref(1)
    const query = ref('')
    const perPage = ref(12)
    const pageOptions = [10, 25, 50, 100]
    const items = ref([])
    const listFotos = ref([])
    const refSidebarFormProducto = ref(null)
    const totalItems = ref(0)
    const totalPages = ref(0)
    const from = ref(0)
    const to = ref(0)
    const of = ref(0)
    const totalRows = ref(0)
    const isBusy = ref(true)
    const modalshow = ref(false)
    const itemSelected = ref({
      title: '',
      descripcion: '',
      precio: '',
      fotos: [],
      place: { titulo: '', servicio: '' },
      destino: '',
      direccion: '',
    })
    const searchADR = ref([
      {
        text: 'Todos',
        value: '',
      },
      {
        text: 'Pendientes',
        value: 'R',
      },
      {
        text: 'Aprobados',
        value: 'A',
      },
      {
        text: 'Desaprobados',
        value: 'D',
      },
    ])
    const selectADR = ref({ text: 'Todos', value: '' })

    const getProducto = () => {
      isBusy.value = true
      const directorio = JSON.parse(localStorage.getItem('empresa'))
      store
        .dispatch('plataforma/PRODUCTO_FIND_ALL', {
          estadoProducto: selectADR.value.value,
          limit: perPage.value,
          page: page.value,
          query: query.value,
          idEmpresa: directorio.idDirectorio,
        })
        .then(response => {
          items.value = response.items
          totalRows.value = response.totalRows
          totalItems.value = response.totalItems
          totalPages.value = response.totalPage
          from.value = perPage.value * (page.value - 1) + (totalRows.value ? 1 : 0)
          to.value = perPage.value * (page.value - 1) + totalRows.value
          of.value = totalItems.value
        })
        .catch(() => {
          notify('Ha ocurrido un error', 'Intentelo en otro momento.', 'danger')
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    watch([query, page, perPage, selectADR], () => {
      getProducto()
    })

    const {
      event,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      loading,
      isEventHandlerSidebarActive,
    } = useProducto(refSidebarFormProducto, props, emit, getProducto)

    const imgToFile = (imagenes => {
      const img = imagenes.split('***')
      listFotos.value = []
      img.map(foto => listFotos.value.push(
        { source: `${config.baseURL}${config.contextPI}/api/v1/multimedia/byUrl?isthumb=true&url=${foto}`,
          options: {
            type: 'remote',
          } },

      ))
      return listFotos.value
    })
    const openForm = type => {
      formType.value = type
      isToggleSidebar.value = true
    }

    const editProducto = async item => {
      const editedItem = { ...item }
      editedItem.myFiles = item.imagenes && item.imagenes !== '' ? imgToFile(item.imagenes) : []
      formType.value = 'edit'
      isToggleSidebar.value = true
      dataEdit.value = editedItem
    }
    const refetchData = response => {
      let message = 'El proceso se ha ejecutado satisfactoriamente.'
      if (response.message) {
        message = response.message
      }
      notify('Operación Extitosa', message, 'success')
      getProducto()
    }
    return {
      page,
      query,
      perPage,
      totalItems,
      totalPages,
      from,
      to,
      of,
      totalRows,
      pageOptions,
      getProducto,
      items,
      isBusy,
      modalshow,
      listFotos,
      searchADR,
      selectADR,
      itemSelected,
      /* edit create */
      event,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      loading,
      editProducto,
      refSidebarFormProducto,
      /*

      */
      isEventHandlerSidebarActive,
      imgToFile,
      moment,

      formatNumberWithCommas,
      dataEdit,
      isToggleSidebar,
      formType,
      refetchData,
      openForm,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.header-df {
  background-color: rgba(34, 41, 47, 0.03) !important;
  border-bottom: 1px solid rgba(34, 41, 47, 0.125) !important;
}

.nav-tabs {
  background: #f6f6f6 !important;
}

.actions-bar {
  background: #f6f6f6 !important;
  justify-content: end;
}

.form-group {
  margin-bottom: 0.5rem !important;
}

#seccion-content.card > .card-body {
  padding-top: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#seccion-content {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
