<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isToggleSidebar"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @hidden="resetForm"
      @change="(val) => toggleSidebar(val)"
    >
      <!--  @change="val => $emit('update:is-event-handler-sidebar-active', val)" -->
      <template #default="{ hide }">
        <!-- Header -->
        <b-tabs>
          <b-tab active>
            <template #title>
              <feather-icon icon="ShoppingBagIcon" />
              <span> {{ titleForm }}</span>
            </template>

            <b-card-text>
              <!-- Form -->
              <b-form
                class="p-2"
                @reset.prevent="resetForm"
              >
                <!-- Title -->
                <b-form-group
                  label="Nombre"
                  label-for="nombre"
                >
                  <b-form-input
                    id="nombre"
                    v-model="dataRegister.nombre"
                    autofocus
                    trim
                    placeholder="Nombre de Producto"
                  />
                </b-form-group>

                <b-form-group
                  label="Precio"
                  label-for="price"
                >
                  <b-form-input
                    id="price"
                    v-model="dataRegister.precioUnitario"
                    type="number"
                    autofocus
                    trim
                    placeholder="Precio de Producto"
                  />
                </b-form-group>

                <b-form-group
                  label="Descripción del producto"
                  label-for="descripcion"
                >
                  <b-form-textarea
                    id="descripcion"
                    v-model="dataRegister.descripcion"
                    rows="6"
                  />
                </b-form-group>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-2"
                    type="button"
                    @click="saveForm"
                  >
                    Guardar
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="button"
                    variant="outline-secondary"
                    @click="hide"
                  >
                    Cancelar
                  </b-button>
                </div>
              </b-form>
            </b-card-text>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="ImageIcon" />
              <span>Fotos</span>
            </template>

            <b-card-text>
              <b-alert
                variant="primary"
                show
              >
                <div class="alert-body">
                  <feather-icon
                    class="mr-25"
                    icon="InfoIcon"
                  />
                  <span
                    class="ml-25"
                  ><strong>Imágenes registradas para el prodcuto.</strong>
                    Dimensiones recomendadas 1000x667, cantidad mínima ideal: 7
                    imágenes.</span>
                </div>
              </b-alert>
              <file-pond
                ref="pondEdit"
                name="archivo"
                label-idle="Arrastre los archivos aquí o <span class='filepond--label-action'>Seleccionelos</span>"
                allow-multiple="true"
                allow-reorder="true"
                accepted-file-types="image/jpeg, image/png"
                :files="myFiles"
                @removefile="onRemoveFile"
                @addfile="onFileAdded"
              />
              <!-- <file-pond
                  @removefile="onRemoveFile"
                v-else
                ref="pondAdd"
                name="archivo"
                label-idle="Arrastre los archivos aquí o <span class='filepond--label-action'>add Seleccionelos</span>"
                allow-multiple="true"
                allow-reorder="true"
                accepted-file-types="image/jpeg, image/png"
                :files="eventLocal.myFiles"
                @addfile="onFileAdded"
                @init="addInit"
              /> -->
            </b-card-text>
          </b-tab>
        </b-tabs>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTabs,
  BTab,
  BCardText,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import config from '@/services/config'
import vueFilePond from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import store from '@/store'
import { showToast, showError } from '@/helpers'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
)
export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    BCardText,
    BFormTextarea,
    BAlert,
    FilePond,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isToggleSidebar',
    event: 'update:update:is-toggle-sidebar',
  },
  props: {
    isToggleSidebar: {
      type: Boolean,
      required: true,
    },
    formType: {
      type: String,
      required: true,
    },
    dataEdit: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      seccion: '',
      orientacion: 'H',
      idDestinoTuristico: 0,
      idFestividad: 0,
      outServer: {},

    }
  },
  mounted() {
  },
  methods: {
    updateInit() {},
    addInit() {},

    // eslint-disable-next-line no-unused-vars
    loadRegisters(listaImg) {
      /*  this.myFiles = []
      console.log('auqi toy ', listaImg)
      listaImg.forEach(item => {
        this.myFiles.push({
          source: item,
          options: {
            type: 'local',
          },
        })
      })
      console.log('que cha fue', this.myFiles) */
    },
    cleanListFoto(val) {
      if (!val) {
        this.myFiles = []
      }
      this.$emit('update:clear-event-data')
      this.$emit('update:is-event-handler-sidebar-active', val)
    },
    async reorderFiles(files) {
      let idsMult = ''
      files.forEach(file => {
        const paramsUrl = file.serverId.split('/')
        const idMult = paramsUrl[paramsUrl.length - 2]
        if (idsMult.length !== 0) {
          idsMult += '&'
        }
        idsMult += `multimedias%5B%5D=${idMult}`
      })
      await store
        .dispatch('multimedia/MULTIMEDIA_SORT', {
          multimedias: idsMult,
        })
        .then(response => {
          this.showToast('Operación Extitosa', response.message, 'success')
        })
        .catch(error => {
          this.showToast('Ha ocurrido un error', error, 'danger')
        })
    },

    handleFilePondInit() {},
    showToast,
    showError,
  },
  setup(props, { emit }) {
    const titleForm = ref('')
    const dataRegister = ref({})

    const { pathRepo, contextBO } = config
    const pathMultimedia = ref(`${pathRepo}${contextBO}/api/v1/multimedia`)
    const myFiles = ref([])

    const resetForm = () => {
      dataRegister.value = {}
      myFiles.value = []
    }

    const onFileAdded = (error, file) => {
      myFiles.value.push(file)
    }

    const onRemoveFile = (error, file) => {
      myFiles.value.forEach(item => {
        if (item.source === file.source) {
          // eslint-disable-next-line no-param-reassign
          item.isremove = true
        }
      })
    }

    const saveForm = async () => {
      const formDataProducto = new FormData()
      let service = 'panel/PRODUCTO_CREATE'

      if (props.formType === 'edit') {
        service = 'panel/PRODUCTO_UPDATE'
        formDataProducto.append('idProducto', dataRegister.value.idProducto)
      }
      const directorio = JSON.parse(localStorage.getItem('empresa'))
      formDataProducto.append('idEmpresa', directorio.idDirectorio)
      formDataProducto.append('descripcion', dataRegister.value.descripcion)
      formDataProducto.append('nombre', dataRegister.value.nombre)
      formDataProducto.append('precioUnitario', dataRegister.value.precioUnitario)

      myFiles.value.forEach(async file => {
        if (!file.isremove) {
          formDataProducto.append('imagenes', file.file, file.file.name)
        }
      })

      await store
        .dispatch(service, formDataProducto)
        .then(async response => {
          emit('refetch-data', response)
          emit('update:is-toggle-sidebar', false)
        })
        .catch(error => {
          emit('error-data', error)
        }).finally(() => {})
    }

    const toggleSidebar = val => {
      resetForm()
      emit('update:is-toggle-sidebar', val)
      if (props.formType === 'new') {
        titleForm.value = 'Agregar Producto'
      }
      if (props.formType === 'edit') {
        dataRegister.value = props.dataEdit
        myFiles.value = dataRegister.value.myFiles
        titleForm.value = 'Editar Producto'
      }
    }

    return {
      resetForm,
      toggleSidebar,
      titleForm,
      dataRegister,
      saveForm,
      /* onRemoveFile, */
      onFileAdded,
      onRemoveFile,
      pathMultimedia,
      myFiles,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
